<template>
  <div class="the-navbar__user-meta flex items-center" v-if="activeUser.id">

    <div class="text-right leading-tight hidden sm:block">
      <p class="font-semibold">{{ activeUser.first_name }} {{ activeUser.last_name }}</p>
      <small>{{activeUser.chat_status}}</small>
    </div>

    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">

      <div class="con-img ml-3">
        <img v-if="activeUser.image" key="onlineImg" :src="absoluteUrl(activeUser.image)" alt="user-img" width="32" height="32" class="rounded-full shadow-md cursor-pointer block" />
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">
          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push({path: '/account' })">
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Profile</span>
          </li>

          <vs-divider class="m-1" />

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push({path: '/chat' })">
            <feather-icon icon="MessageSquareIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Chat</span>
          </li>
          <vs-divider class="m-1" />

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="logout()">
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Logout</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>

export default {
  data() {
    return {

    }
  },
  computed: {
    activeUser() {
      return this.$store.state.AppActiveUser
    }
  },
  methods: {
    logout() {
      this.$vs.loading({background: "#fff", type: "none"})
      this.$http.defaults.headers.Authorization = "";
      this.$store.dispatch("logoutUser")
    },
  }
}
</script>
