<template>
  <span class="flex align-center">
    <feather-icon icon="MessageSquareIcon" @click="$router.push(`/chat`).catch(() => {})" class="target-message cursor-pointer w-6 h-6 ml-4 mr-2" :badge="unread_chats" />
  </span>
</template>

<script>

export default {
  computed: {
    unread_chats(){
      return this.$store.state.activeUserUnreadChats
    }
  },
}
</script>
